@import 'variables.custom';

body:has(.onboard) {
  .btn {
    font-weight: 600;
  }
}

.hide-header {
  display: none;
}

.shopify-popover.batches {
  top: 49px !important;
  right: -159px !important;

  .shopify-tool-tip {
    width: 212px;
    height: 42px;
  }
}

.batches-list {
  width: 100%;
}

.help-center {
  display: inline-flex;
  float: right;
  margin-top: 3px;

  .question-help {
    margin-top: 2px;
  }
}

.btn-help-center-widget {
  z-index: 1;
}
#btn-help-center-widget {
  &:hover {
    background-color: transparent !important;
  }
}

#help-center-widget-tooltip-top {
  .tooltip-inner {
    max-width: max-content;
  }
  .tooltip-arrow {
    display: none;
  }
}

#gori_modal_credit_card_ach {
  .modal-body {
    padding: 20px 28px;
  }

  .customer-information-element {
    padding-bottom: 17px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cbd4e1;

    &:last-of-type {
      border-bottom: 0;
    }

    .row.my-4 {
      .col-md-4 {
        @media (max-width: 767px) {
          margin-bottom: 5px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .customer-information-text {
      margin-left: 5px;

      span {
        margin-top: 15px;
        font-weight: 600;
      }

      p {
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
  }

  .customer-information {
    justify-content: space-between;
    margin: 18px auto;

    .col-md-5 {
      width: 48%;
      margin: auto 5px;

      img {
        width: 19px;
        float: right;
      }

      .input-radio {
        display: flex;

        .mt-4 {
          margin-right: 30px;
        }
      }

      &.signature,
      &.signature-drawing-custom,
      &.signature-drawing-custom .signature-textarea {
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }

    .signature {
      .sigPadContainer {
        background: $gray-text-bg;
      }

      .sigCanvas {
        width: 100%;
        height: 135px;
      }

      .sigPadContainer.error {
        border: 1px solid red;
      }
    }

    .option-signature {
      margin-bottom: 17px;
      margin-top: 3px;
    }

    .signature-textarea {
      margin-top: 15px;
      padding: 10px;
      background: $gray-text-bg;
      width: 333px;
      height: 135px;
      border-radius: 8px;
      text-align: center;
      word-break: break-word;
      align-items: center;
      justify-content: center;
      display: table;

      .textarea {
        display: table-cell;
        vertical-align: middle;
      }

      span {
        font-family: 'Mrs Saint Delafield', cursive;
        font-size: 48px;
        line-height: 48px;
        text-align: center;
        margin: auto;
        vertical-align: middle;
        word-break: break-word;
      }
    }
  }
}

.btn.help-center {
  float: right;
  border: 1px solid #f1f4f6 !important;
  border-radius: 20px;
  background: #ffffff;
}

#onboard {
  .onboard-logo {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .steps-container {
    text-align: center;
  }

  .step-box {
    width: 20%;
    display: inline-block;
    position: relative;
    padding-top: 24px;
  }

  .step-block {
    width: 200px;
    border-radius: 50px;
    height: 48px;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    font-size: 16px;
    color: #a1a5b7;
    display: block;
    border: 2px solid #a1a5b7;
    z-index: 99999;
  }

  .step-title {
    line-height: 43px;
    font-weight: 600;
    color: #a1a5b7;

    span {
      padding-right: 7px;
    }
  }

  .step-border {
    position: absolute;
    top: 45px;
    height: 2px;
    border-top: 2px solid #a1a5b7;
    right: 0;
    left: 0;
    z-index: -1;
  }

  .step-box:first-child .step-border {
    left: 50% !important;
  }

  .step-box:last-child .step-border {
    right: 50% !important;
  }

  .onboard-select {
    background: #fff;
  }

  .title-active {
    display: none;
  }

  .active {
    .title-active {
      display: block !important;
    }

    .step-block {
      width: 200px;
      border-radius: 50px;
      border-color: $primary;
      height: 56px;
      margin-top: -4px;
    }

    .step-title {
      line-height: 50px;
      color: #000;
    }

    .step-title span {
      font-size: 17px;
      color: $primary;
      padding-right: 7px;
    }
  }

  .welcome {
    .welcome-img {
      max-width: 100%;
      width: 800px;
      background: #f9fafb;
      margin: 50px 0;
    }

    p {
      font-weight: 600;
      font-size: 16px;
    }

    .partypopper {
      width: 25px;
      vertical-align: sub !important;
      margin-left: 7px;
    }
  }

  .choose-box {
    padding: 26px;
    height: 100%;
    cursor: pointer;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 2px solid #eff2f5;
    border-radius: 0.85rem;

    .svg-icon svg {
      width: 72px;
      height: 72px;
      max-height: 100% !important;
    }

    .choose-text {
      font-size: 16px;
      font-weight: 600;
      height: 48px;
      display: inline-flex;
      align-items: center;
    }
  }

  .choose-box.label {
    padding: 20px 15px 15px;

    .choose-text-label {
      font-size: 16px;
      font-weight: 600;
      margin-top: 15px;
    }

    p {
      font-size: 13px;
    }
  }

  .partypopper {
    width: 25px;
    margin-left: 7px;
    margin-top: -5px;
  }

  .icon-tool-tip-mark {
    width: 21px;
    vertical-align: sub !important;
    position: relative;
    top: 22px;
  }

  .choose-box.label:hover,
  .choose-box.label.active {
    border-color: $primary;
    color: $primary;

    .svg-icon svg path {
      stroke: $primary !important;
      fill: transparent !important;
    }
  }

  .choose-box:hover,
  .active {
    border-color: $primary;

    .svg-icon svg path {
      fill: $primary !important;
    }

    .choose-text {
      color: $primary;
    }
  }

  .address-complete {
    .col-9,
    .col-md-9 {
      width: 100%;
    }

    .offset-md-3 {
      margin-left: 0;
    }
  }

  .account-step {
    width: 35%;
    margin: 0 auto;

    .account-timezone {
      margin-bottom: 30px;
    }

    .low-balance-title {
      font-size: 1.35rem;
      font-weight: 600;
    }

    .checkbox-balance {
      margin-right: 14px;
    }

    .account.text-center {
      text-align: left !important;
      margin-top: 18px;
    }

    .timezone-select {
      margin-left: 53px;
      width: 94%;
    }

    p.account-time-zone {
      font-size: 16px;
      font-weight: 600;
    }

    .save-button {
      button.btn.btn-primary {
        padding: 8px 27px !important;
        float: right;
      }
    }

    .text-bold {
      font-weight: bold;
    }
  }

  .add-fund {
    padding: 35px;

    .add-fund-text {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      width: 45%;
      margin: 0 auto 30px;
    }

    .choose-box {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .skip {
    cursor: pointer;
    margin-top: 20px;
    font-weight: 600;
    font-size: 15px;
    text-align: right;
    padding-right: 15px;
    color: #a1a5b7;
  }
}

.text-submitted {
  margin-top: 35px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

.add-fund-transaction {
  margin: 30px 0;

  p {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
  }
}

.signature.drawing {
  width: 100% !important;

  .sigPadContainer {
    float: right;
    height: 135px;
    margin-top: -67px;
    width: 48%;
    margin-right: 9px;
    border-radius: 8px;
  }

  .option-signature {
    width: 48%;
    margin-bottom: 8px;
  }
}

.input-group.error-date {
  fieldset {
    border-color: #f1416c !important;
  }
}

.back.onboard-form {
  padding: 11px 18px !important;
  border-radius: 8px;
  float: left;
  background: #eff2f5 !important;
}

.save-and-exit {
  display: block;
  margin-bottom: 40px;
  border-radius: 8px;
  padding: 8px 12px !important;

  @media (min-width: 1540px) and (min-height: 920px) {
    display: block;
    position: fixed;
    right: 40px;
    bottom: 40px;
    margin-bottom: 0;
  }

  span.indicator-label {
    color: #9497a3;
    font-size: 14px;
    letter-spacing: -0.308px;
  }

  span.svg-icon {
    padding-left: 6px;
  }

  .indicator-progress {
    display: inline-block !important;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.286px;
  }
}

.add-fund {
  .save-and-exit {
    padding: 8px 0 70px;
  }
}

.connection {
  margin: 35px 0;

  span {
    margin-left: 5px;
    max-height: 30px !important;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
